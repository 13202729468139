import React from "react";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  GlobalStyles,
  Grid,
  Toolbar,
  Typography,
  Link,
  Stack,
  Paper,
} from "@mui/material";
import StarIcon from "@mui/icons-material/StarBorder";
import LbdevLogo from "../../components/LbdevLogo";
import Copyright from "../../components/Copyright";

type Tier = {
  title: string;
  subheader?: string;
  price: string;
  description: string[];
  buttonText: string;
  buttonVariant: "outlined" | "contained";
};

const tiers: Tier[] = [
  {
    title: "Starter",
    price: "100",
    description: [
      "1 Replica",
      "2GB Armazenamento",
      "Backup mensal do banco de dados *",
    ],
    buttonText: "Entre em contato",
    buttonVariant: "outlined",
  },
  {
    title: "Premium",
    subheader: "Mais popular",
    price: "250",
    description: [
      "Até 3 replicas",
      "Balanceamento de carga",
      "10GB de armazenamento",
      "Backup semanal do banco de dados *",
    ],
    buttonText: "Entre em contato",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise",
    price: "500",
    description: [
      "Até 5 replicas",
      "Balanceamento de carga",
      "100GB de armazenamento",
      "Backup diário do banco de dados *",
    ],
    buttonText: "Entre em contato",
    buttonVariant: "outlined",
  },
];

const footer = [
  {
    title: "Legal",
    description: [
      // {
      //   title: "Termos de uso",
      //   href: "/termos-de-uso",
      // },
      {
        title: "Política de privacidade",
        href: "/privacy-police",
      },
    ],
  },
];

class HomePage extends React.PureComponent {
  render() {
    return (
      <>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Container maxWidth="md">
              <Toolbar sx={{ flexWrap: "wrap" }}>
                <Box
                  color="inherit"
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LbdevLogo height="32" />
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Container
              disableGutters
              maxWidth="sm"
              component="main"
              sx={{
                pt: 8,
                pb: 6,
              }}
            >
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Planos
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                component="p"
              >
                Escolha o plano que melhor se encaixa no seu negócio.
              </Typography>
            </Container>
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {tiers.map((tier) => (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={tier.title === "Enterprise" ? 12 : 6}
                    md={4}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        action={tier.title === "Premium" ? <StarIcon /> : null}
                        sx={(theme) => ({
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? theme.palette.grey[200]
                              : theme.palette.grey[700],
                        })}
                      />
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "baseline",
                            mb: 2,
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h3"
                            color="text.primary"
                          >
                            R$ {tier.price}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            /mês
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <ul>
                            {tier.description.map((line) => (
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                key={line}
                              >
                                {line}
                              </Typography>
                            ))}
                          </ul>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Button
                          type="link"
                          href="mailto://contato@lbdev.com.br"
                          fullWidth
                          variant={tier.buttonVariant}
                          color="primary"
                        >
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
                {/* Solicite seu orçamento */}
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    // variant="outlined"
                    sx={{
                      p: 2,
                    }}
                  >
                    <Stack spacing={2}>
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h6"
                          color="text.primary"
                        >
                          Ainda não possuí uma aplicação? Podemos desenvolver
                          para você. <br />
                          Utilizamos as melhores tecnologias do mercado.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          height: "100%",

                          flexBasis: "min-content",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          type="link"
                          href="mailto://contato@lbdev.com.br"
                          variant="contained"
                        >
                          Solicite seu orçamento
                        </Button>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container
            maxWidth="md"
            component="footer"
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
              mt: 8,
              py: [3, 6],
            })}
          >
            <Grid container spacing={2} rowSpacing={4}>
              <Grid item xs={12} md={6}>
                <Stack
                  spacing={1}
                  sx={{
                    textAlign: {
                      xs: "center",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      textAlign={"center"}
                    >
                      Email
                    </Typography>
                    <Link
                      href="mailto://contato@lbdev.com.br"
                      gutterBottom
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      contato@lbdev.com.br
                    </Link>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" color="text.secondary">
                      Telefone
                    </Typography>
                    <Link
                      href="tel:+558331422075"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      +55 83 3142-2075
                    </Link>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={4} justifyContent="space-evenly">
                  {footer.map((section) => (
                    <Grid item xs={6} key={section.title}>
                      <Box
                        component="ul"
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {section.description.map((item) => (
                          <li key={item.title}>
                            <Link
                              href={item.href}
                              color="text.secondary"
                              sx={{
                                textDecoration: "none",
                              }}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
                fontSize="0.8rem"
              >
                LBDEV SERVICOS DE INFORMACAO NA INTERNET LTDA.
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
                fontSize="0.8rem"
              >
                48.829.694/0001-09
              </Typography>
            </Box>
            <Copyright
              sx={{
                mt: 1,
              }}
            />
          </Container>
        </Box>
      </>
    );
  }
}

export default HomePage;
