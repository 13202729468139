import { useRoutes } from "react-router-dom";
import HomePage from "../pages/home";
import PrivacyPolicePage from "../pages/privacyPolice";

const Routes = () => {
  return useRoutes([
    {
      path: "/",
      children: [
        { path: "/", element: <HomePage /> },
        {
          path: "privacy-police",
          element: <PrivacyPolicePage />,
        },
      ],
    },
  ]);
};

export default Routes;
