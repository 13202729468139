import React from "react";
import {
  Box,
  Container,
  Link,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  Typography,
  TypographyProps,
} from "@mui/material";
import LbdevLogo from "../../components/LbdevLogo";
import Copyright from "../../components/Copyright";

const P = (props: TypographyProps<"p">) => {
  return <Typography variant="body1" component="div" paragraph {...props} />;
};

const Topic = (props: TypographyProps<"h2">) => {
  return (
    <Typography
      variant="h5"
      component="h2"
      fontWeight={700}
      gutterBottom
      {...props}
    />
  );
};

const SubTopic = (props: TypographyProps<"h3">) => {
  return (
    <Typography
      variant="h6"
      component="h3"
      fontWeight={700}
      gutterBottom
      {...props}
    />
  );
};

const ListItem = (props: TypographyProps<"li">) => {
  return (
    <Typography
      variant="body1"
      component="li"
      paragraph
      fontWeight={500}
      {...props}
    />
  );
};

const browserCookieLinks = [
  {
    name: "Internet Explorer",
    cookieLink:
      "https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies",
  },
  {
    name: "Google Chrome",
    cookieLink: "https://support.google.com/chrome/answer/95647?hl=pt-BR",
  },
  {
    name: "Mozilla Firefox",
    cookieLink:
      "https://support.mozilla.org/pt-BR/kb/ativar-e-desativar-cookies-que-os-sites-usam",
  },
  {
    name: "Safari",
    cookieLink: "https://support.apple.com/pt-br/guide/safari/sfri11471/mac",
  },
  {
    name: "Opera",
    cookieLink: "https://help.opera.com/pt-BR/latest/web-preferences/#cookies",
  },
  {
    name: "Microsoft Edge",
    cookieLink:
      "https://support.microsoft.com/pt-br/help/4027947/windows-delete-cookies",
  },
];

const Table = (props: TableProps) => {
  return (
    <MuiTable
      sx={{
        "& .MuiTableCell-root:not(:first-of-type)": {
          borderLeft: "1px solid rgba(224, 224, 224, 1)",
        },
      }}
      {...props}
    />
  );
};

const PrivacyPolicePage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          textAlign: "center",
          py: 4,
        }}
      >
        <Box
          sx={{
            py: [4, 2],
          }}
        >
          <LbdevLogo height="32" />
        </Box>
        <Typography variant="h4" component="h1" gutterBottom fontWeight={700}>
          Política de Privacidade
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 4,
        }}
      >
        <P>
          Na lbdev, privacidade e segurança são prioridades e nos comprometemos
          com a transparência do tratamento de dados pessoais dos nossos
          usuários/clientes. Por isso, nesta presente Política de Privacidade
          estabelece como é feita a coleta, uso e transferência de informações
          de clientes ou outras pessoas que acessam ou usam nosso site.
        </P>
        <P>
          Ao utilizar nossos serviços, você entende que coletaremos e usaremos
          suas informações pessoais nas formas descritas nesta Política, sob as
          normas da Constituição Federal de 1988 (art. 5º, LXXIX; e o art. 22º,
          XXX – incluídos pela EC 115/2022), das normas de Proteção de Dados
          (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei
          Federal 8078/1990 e as demais normas do ordenamento jurídico
          brasileiro aplicáveis. Dessa forma, a{" "}
          <b>LBDEV SERVICOS DE INFORMACAO NA INTERNET LTDA</b>, doravante
          denominada simplesmente como “lbdev”, inscrita no CNPJ/MF sob o nº{" "}
          <b>48.829.694/0001-09</b>, no papel de Controladora de Dados,
          obriga-se ao disposto na presente Política de Privacidade.
        </P>
        <Topic>
          1. Quais dados coletamos sobre você e para qual finalidade?
        </Topic>
        <P>
          Visando proporcionar a prestação de serviços e atender às expectativas
          do público, a <b>LBDEV SERVICOS DE INFORMACAO NA INTERNET LTDA</b>.
          realiza a coleta e o tratamento de dados pessoais de seus usuários, em
          conformidade com a legislação brasileira de proteção de dados e boas
          práticas de governança. Dentre os dados coletados, pode-se destacar:
        </P>
        <SubTopic>1.1. Dados pessoais fornecidos pelo usuário</SubTopic>
        <P>
          Os dados pessoais fornecidos pelo usuário são tratados seguindo as
          seguintes finalidades:
        </P>
        <ul>
          <ListItem>
            Cadastrar informações para identificação do usuário e cumprimento
            das obrigações decorrentes da prestação dos serviços lbdev.
          </ListItem>
          <ListItem>
            Cumprir obrigações legais e regulatórias do ordenamento brasileiro
          </ListItem>
          <ListItem>
            Possibilitar o contato entre lbdev e os usuários, a fim de mantê-los
            informados sobre atualizações em nossos serviços, de divulgar
            conteúdos de valor por email e por mensagens automatizadas no
            celular, realizar solicitações, bem como qualquer outra necessidade
            comunicativa percebida entre as duas partes.
          </ListItem>
          <ListItem>
            Possibilitar estudos de público para a criação de
            anúncios/propagandas, com base em boas práticas de segmentação do
            público e visando à melhor experiência do usuário.
          </ListItem>
          <ListItem>
            Possibilitar o aprimoramento da qualidade do serviço para os
            clientes.
          </ListItem>
          <ListItem>
            Assegurar o pagamento da prestação dos serviços lbdev e prevenir
            eventuais divergências ou dificuldades para as partes.
          </ListItem>
          <ListItem>
            Assegurar o direito de defesa em caso de eventuais disputas
            judiciais ou exigências administrativas ou judiciais
          </ListItem>
        </ul>
        <P>
          <Container maxWidth="sm">
            <TableContainer component={Paper} variant="outlined" square>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={"60%"}>Dados</TableCell>
                    <TableCell>
                      Obrigatório para utilização do serviço?
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-mai;</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Opcional</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CPF ou CNPJ</TableCell>
                    <TableCell>Opcional</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dados de pagamento e cobrança</TableCell>
                    <TableCell>Quando cabível</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </P>
        <SubTopic>1.2. Dados pessoais coletados automaticamente</SubTopic>
        <ul>
          <ListItem>
            Possibilitar a melhoria dos serviços digitais, do sistema técnico e
            da experiência de uso e a segurança do usuário ao utilizar os
            serviços da lbdev.
          </ListItem>
          <ListItem>
            Cumprimento de obrigações legais e regulatórias do ordenamento
            brasileiro.
          </ListItem>
          <ListItem>
            Possibilitar estudos de público para a criação de
            anúncios/propagandas, com base em boas práticas de segmentação do
            público e visando à melhor experiência do usuário.
          </ListItem>
          <ListItem>
            Monitoramento da atividade do usuário nas páginas online da lbdev,
            para fins de planejamento e aperfeiçoamento do serviço e da
            experiência do usuário, bem como para prevenir fraudes e
            comportamentos suspeitos.
          </ListItem>
        </ul>
        <P>
          <Container maxWidth="sm">
            <TableContainer component={Paper} variant="outlined" square>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={"60%"}>Dados</TableCell>
                    <TableCell>
                      Obrigatório para utilização do serviço?
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Navegador</TableCell>
                    <TableCell>Quando cabível</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Plataforma do dispositivo, Sistema operacional e Tipo de
                      dispositivo
                    </TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Telas visitadas na plataforma</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Endereço IP</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Histórico de uso do usuário</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Geolocalização</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Identificação do aparelho utilizado</TableCell>
                    <TableCell>Sim</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </P>
        <P>
          Além dos dados mencionados, outros dados pessoais podem ser utilizados
          para assegurar a prestação dos serviços lbdev e atender às
          expectativas dos usuários. Tais dados acompanharão as finalidades
          descritas acima, bem como outras finalidades lícitas e em conformidade
          com a Lei Geral de Proteção de Dados.
        </P>
        <Topic>2. Como coletamos os seus dados?</Topic>
        <P>
          Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte
          forma:
        </P>
        <ul>
          <ListItem>
            <b>Quando você cria uma conta na plataforma:</b> coleta-se seus
            dados de identificação básicos para acessar as funcionalidades da
            plataforma. Esses dados são tais que nome e endereço de e-mail.
          </ListItem>
          <ListItem>
            <b>
              Quando você completa o preenchimento de seu cadastro na
              plataforma:
            </b>{" "}
            você tem a opção de fornecer dados complementares, como o número de
            telefone.
          </ListItem>
          <ListItem>
            <b>Quando você usa a plataforma:</b> durante o uso, informações
            inseridas por você podem ser coletadas, como os dados inseridos para
            contato.
          </ListItem>
          <ListItem>
            <b>Por parceiros comerciais:</b> nossos parceiros (operadores)
            comerciais têm somente somente acesso a dados imprescindíveis para a
            prestação dos serviços oferecidos pela lbdev.
          </ListItem>
        </ul>
        <P>
          A lbdev também coleta uma série de dados de modo automático, como
          origem do IP, a identificação do aparelho utilizado no acesso e do
          navegador, informações sobre telas acessadas na plataforma. Os dados
          solicitados não serão compartilhados com terceiros não autorizados e
          apenas utilizados para o propósito que motivou o cadastro, conforme as
          finalidades descritas no Tópico 1.
        </P>
        <SubTopic>2.1. Consentimento</SubTopic>
        <P>
          É a partir do seu consentimento que tratamos os seus dados pessoais. O
          consentimento é a manifestação livre, informada e inequívoca pela qual
          você autoriza a lbdev a tratar seus dados.
        </P>
        <P>
          Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados
          só serão coletados, tratados e armazenados mediante prévio e expresso
          consentimento.
        </P>
        <P>
          O seu consentimento será obtido de forma específica para cada
          finalidade acima descrita, evidenciando o compromisso de transparência
          e boa-fé da lbdev para com seus usuários/clientes, seguindo as
          regulações legislativas pertinentes. Ao utilizar os serviços da lbdev
          e fornecer seus dados pessoais, você está ciente e consentindo com as
          disposições desta Política de Privacidade, além de conhecer seus
          direitos e como exercê-los.
        </P>
        <P>
          A qualquer tempo e sem nenhum custo, você poderá revogar seu
          consentimento. É importante destacar que a revogação do consentimento
          para o tratamento dos dados pode implicar a impossibilidade da
          performance adequada de alguma funcionalidade do site que dependa da
          operação. Tais consequências serão informadas previamente.
        </P>
        <Topic>3. Quais são os seus direitos?</Topic>
        <P>
          A lbdev assegura a seus usuários/clientes seus direitos de titular
          previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma,
          você pode, de maneira gratuita e a qualquer tempo:
        </P>
        <ul>
          <ListItem>
            <b>Confirmar a existência de tratamento de dados</b>, de maneira
            simplificada ou em formato claro e completo.
          </ListItem>
          <ListItem>
            <b>Acessar seus dados</b>, podendo solicitá-los em uma cópia legível
            sob forma impressa ou por meio eletrônico, seguro e idôneo.
          </ListItem>
          <ListItem>
            <b>Corrigir seus dados</b>, ao solicitar a edição, correção ou
            atualização destes
          </ListItem>
          <ListItem>
            <b>Limitar seus dados</b> quando desnecessários, excessivos ou
            tratados em desconformidade com a legislação através da
            anonimização, bloqueio ou eliminação.
          </ListItem>
          <ListItem>
            <b>Solicitar a portabilidade de seus dados</b>, através de um
            relatório de dados cadastrais que a lbdev trata a seu respeito.
          </ListItem>
          <ListItem>
            <b>Eliminar seus dados</b> tratados a partir de seu consentimento,
            exceto nos casos previstos em lei.
          </ListItem>
          <ListItem>
            <b>Revogar seu consentimento</b>, desautorizando o tratamento de
            seus dados.
          </ListItem>
          <ListItem>
            <b>
              Informar-se sobre a possibilidade de não fornecer seu
              consentimento
            </b>{" "}
            e sobre as consequências da negativa.
          </ListItem>
        </ul>
        <Topic>4. Como você pode exercer seus direitos de titular?</Topic>
        <P>
          Para exercer seus direitos de titular, você deve entrar em contato com
          a lbdev através dos seguintes meios disponíveis:
        </P>
        <ul>
          <ListItem mb={0.5}>
            <b>E-mail</b>:{" "}
            <Link href="mailto://contato@lbdev.com.br">
              contato@lbdev.com.br
            </Link>
          </ListItem>
          <ListItem>
            <b>Telefone</b>:{" "}
            <Link href="tel:+558331422075">+55 83 3142-2075</Link>
          </ListItem>
        </ul>
        <P>
          De forma a garantir a sua correta identificação como titular dos dados
          pessoais objeto da solicitação, é possível que solicitemos documentos
          ou demais comprovações que possam comprovar sua identidade. Nessa
          hipótese, você será informado previamente.
        </P>
        <Topic>5. Como e por quanto tempo seus dados serão armazenados?</Topic>
        <P>
          Seus dados pessoais coletados pela lbdev serão utilizados e
          armazenados durante o tempo necessário para a prestação do serviço ou
          para que as finalidades elencadas na presente Política de Privacidade
          sejam atingidas, considerando os direitos dos titulares dos dados e
          dos controladores.
        </P>
        <P>
          De modo geral, seus dados serão mantidos enquanto a relação contratual
          entre você e a lbdev perdurar. Findado o período de armazenamento dos
          dados pessoais, estes serão excluídos de nossas bases de dados ou
          anonimizados, ressalvadas as hipóteses legalmente previstas no artigo
          16 lei geral de proteção de dados, a saber:
        </P>
        <ol type="I">
          <ListItem>
            cumprimento de obrigação legal ou regulatória pelo controlador;
          </ListItem>
          <ListItem>
            estudo por órgão de pesquisa, garantida, sempre que possível, a
            anonimização dos dados pessoais;
          </ListItem>
          <ListItem>
            transferência a terceiro, desde que respeitados os requisitos de
            tratamento de dados dispostos nesta Lei; ou
          </ListItem>
          <ListItem>
            uso exclusivo do controlador, vedado seu acesso por terceiro, e
            desde que anonimizados os dados.
          </ListItem>
        </ol>
        <P>
          Isto é, informações pessoais sobre você que sejam imprescindíveis para
          o cumprimento de determinações legais, judiciais e administrativas
          e/ou para o exercício do direito de defesa em processos judiciais e
          administrativos serão mantidas, a despeito da exclusão dos demais
          dados.
        </P>
        <P>
          O armazenamento de dados coletados pela lbdev reflete o nosso
          compromisso com a segurança e privacidade dos seus dados. Empregamos
          medidas e soluções técnicas de proteção aptas a garantir a
          confidencialidade, integridade e inviolabilidade dos seus dados. Além
          disso, também contamos com medidas de segurança apropriadas aos riscos
          e com controle de acesso às informações armazenadas.
        </P>
        <Topic>6. O que fazemos para manter seus dados seguros?</Topic>
        <P>
          Para mantermos suas informações pessoais seguras, usamos ferramentas
          físicas, eletrônicas e gerenciais orientadas para a proteção da sua
          privacidade. Aplicamos essas ferramentas levando em consideração a
          natureza dos dados pessoais coletados, o contexto e a finalidade do
          tratamento e os riscos que eventuais violações gerariam para os
          direitos e liberdades do titular dos dados coletados e tratados. Entre
          as medidas que adotamos, destacamos as seguintes:
        </P>
        <ul>
          <ListItem>
            Apenas pessoas autorizadas têm acesso a seus dados pessoais
          </ListItem>
          <ListItem>
            O acesso a seus dados pessoais é feito somente após o compromisso de
            confidencialidade
          </ListItem>
          <ListItem>
            Seus dados pessoais são armazenados em ambiente seguro e idôneo.
          </ListItem>
        </ul>
        <P>
          A lbdev se compromete a adotar as melhores posturas para evitar
          incidentes de segurança. Contudo, é necessário destacar que nenhuma
          página virtual é inteiramente segura e livre de riscos. É possível
          que, apesar de todos os nossos protocolos de segurança, problemas de
          culpa exclusivamente de terceiros ocorram, como ataques cibernéticos
          de hackers, ou também em decorrência da negligência ou imprudência do
          próprio usuário/cliente.
        </P>
        <P>
          Em caso de incidentes de segurança que possa gerar risco ou dano
          relevante para você ou qualquer um de nossos usuários/clientes,
          comunicaremos aos afetados e a Autoridade Nacional de Proteção de
          Dados sobre o ocorrido, em consonância com as disposições da Lei Geral
          de Proteção de Dados.
        </P>
        <Topic>7. Com quem seus dados podem ser compartilhados?</Topic>
        <P>
          Tendo em vista a preservação de sua privacidade, a lbdev não
          compartilhará seus dados pessoais com nenhum terceiro não autorizado.{" "}
        </P>
        <P>
          Além disso, também existem outras hipóteses em que seus dados poderão
          ser compartilhados, que são:
        </P>
        <ol type="I">
          <ListItem>
            Determinação legal, requerimento, requisição ou ordem judicial, com
            autoridades judiciais, administrativas ou governamentais
            competentes.
          </ListItem>
          <ListItem>
            Caso de movimentações societárias, como fusão, aquisição e
            incorporação, de forma automática
          </ListItem>
          <ListItem>
            Proteção dos direitos da lbdev em qualquer tipo de conflito,
            inclusive os de teor judicial.
          </ListItem>
        </ol>
        <SubTopic>7.1. Transferência internacional de dados</SubTopic>
        <P>
          Alguns dos terceiros com quem compartilhamos seus dados podem ser
          localizados ou ou possuir instalações localizadas em países
          estrangeiros. Nessas condições, de toda forma, seus dados pessoais
          estarão sujeitos à Lei Geral de Proteção de Dados e às demais
          legislações brasileiras de proteção de dados.
        </P>
        <P>
          Nesse sentido, a lbdev se compromete a sempre adotar eficientes
          padrões de segurança cibernética e de proteção de dados, nos melhores
          esforços de garantir e cumprir as exigências legislativas.
        </P>
        <P>
          Ao concordar com essa Política de Privacidade, você concorda com esse
          compartilhamento, que se dará conforme as finalidades descritas no
          presente instrumento.
        </P>
        <Topic>8. Cookies ou dados de navegação</Topic>
        <P>
          A lbdev faz uso de Cookies, que são arquivos de texto enviados pela
          plataforma ao seu computador e que nele se armazenam, que contém
          informações relacionadas à navegação do site. Em suma, os Cookies são
          utilizados para aprimorar a experiência de uso.
        </P>
        <P>
          Ao acessar nosso site e consentir com o uso de Cookies, você manifesta
          conhecer e aceitar a utilização de um sistema de coleta de dados de
          navegação com o uso de Cookies em seu dispositivo.
        </P>
        <P>
          Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões,
          bloquear ou recusar os Cookies. Todavia, a revogação do consentimento
          de determinados Cookies pode inviabilizar o funcionamento correto de
          alguns recursos da plataforma.
        </P>
        <P>
          Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente
          nas configurações do navegador, na área de gestão de Cookies. Você
          pode acessar tutoriais sobre o tema diretamente nos links abaixo:
        </P>
        <Box
          component="ul"
          sx={{
            p: 0,
          }}
        >
          {browserCookieLinks.map((cookie, index) => (
            <ListItem
              key={index}
              sx={{
                listStyleType: "none",
                mb: 0,
              }}
            >
              Se você usa o <Link href={cookie.cookieLink}>{cookie.name}</Link>
            </ListItem>
          ))}
        </Box>
        <Topic>9. Alteração desta Política de Privacidade</Topic>
        <P>
          A atual versão da Política de Privacidade foi formulada e atualizada
          pela última vez em: 10/03/2023.
        </P>
        <P>
          Reservamos o direito de modificar essa Política de Privacidade a
          qualquer tempo, principalmente em função da adequação a eventuais
          alterações feitas em nosso site ou em âmbito legislativo. Recomendamos
          que você a revise com frequência.
        </P>
        <P>
          Eventuais alterações entrarão em vigor a partir de sua publicação em
          nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
        </P>
        <P>
          Ao utilizar nossos serviços e fornecer seus dados pessoais após tais
          modificações, você às consente.
        </P>
        <Topic>10. Responsabilidade</Topic>
        <P>
          A lbdev prevê a responsabilidade dos agentes que atuam nos processos
          de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei
          Geral de Proteção de Dados.
        </P>
        <P>
          Nos comprometemos em manter esta Política de Privacidade atualizada,
          observando suas disposições e zelando por seu cumprimento.
        </P>
        <P>
          Além disso, também assumimos o compromisso de buscar condições
          técnicas e organizativas seguramente aptas a proteger todo o processo
          de tratamento de dados.
        </P>
        <P>
          Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
          providências em relação ao tratamento de dados realizado pela lbdev,
          comprometemo-nos a segui-las.
        </P>
        <SubTopic>10.1 Isenção de responsabilidade</SubTopic>
        <P>
          Conforme mencionado no Tópico 6, embora adotemos elevados padrões de
          segurança a fim de evitar incidentes, não há nenhuma página virtual
          inteiramente livre de riscos. Nesse sentido, a lbdevnão se
          responsabiliza por:
        </P>
        <Box component="ol" type="I">
          <ListItem>
            <P>
              Quaisquer consequências decorrentes da negligência, imprudência ou
              imperícia dos usuários em relação a seus dados individuais.
              Garantimos e nos responsabilizamos apenas pela segurança dos
              processos de tratamento de dados e do cumprimento das finalidades
              descritas no presente instrumento.
            </P>
            <P>
              Destacamos que a responsabilidade em relação à confidencialidade
              dos dados de acesso é do usuário.
            </P>
          </ListItem>
          <ListItem>
            <P>
              Ações maliciosas de terceiros, como ataques de hackers, exceto se
              comprovada conduta culposa ou deliberada da lbdev.
            </P>
            <P>
              Destacamos que em caso de incidentes de segurança que possam gerar
              risco ou dano relevante para você ou qualquer um de nossos
              usuários/clientes, comunicaremos aos afetados e à Autoridade
              Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as
              providências necessárias.
            </P>
          </ListItem>
          <ListItem>
            <P>
              Inveracidade das informações inseridas pelo usuário/cliente nos
              registros necessários para a utilização dos serviços da lbdev;
              quaisquer consequências decorrentes de informações falsas ou
              inseridas de má-fé são de inteiramente responsabilidade do
              usuário/cliente.
            </P>
          </ListItem>
        </Box>
        <Topic>11. Encarregado de Proteção de Dados</Topic>
        <P>
          A lbdev disponibiliza os seguintes meios para que você possa entrar em
          contato conosco para exercer seus direitos de titular: e-mail e
          telefone. Caso tenha dúvidas sobre esta Política de Privacidade ou
          sobre os dados pessoais que tratamos, você pode entrar em contato com
          o nosso Encarregado de Proteção de Dados Pessoais, através dos
          seguintes canais: Luiz Felipe Silva Braga, <i>e-mail</i>{" "}
          <Link href="mailto://contato@lbdev.com.br">contato@lbdev.com.br</Link>
          .
        </P>
      </Box>
      <Copyright />
    </Container>
  );
};

export default PrivacyPolicePage;
